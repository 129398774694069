import { FormSection } from '../models/form.config';

export const statusFormConfig: FormSection[] = [
  {
    key: '',
    title: '',
    fields: [
      {
        id: 'status',
        label: 'Status',
        formControlName: 'Status',
        type: 'autocomplete-static',
        placeholder: 'Select the current status',
        size: 12,
        validators: ['required'],
        options: [],
      },
      {
        id: 'percentage',
        label: '% of Completion',
        formControlName: 'Percentage',
        type: 'number',
        placeholder: 'Enter the completion percentage',
        size: 12,
        validators: ['required'],
      },
      {
        id: 'comment',
        label: 'Comment',
        formControlName: 'Comment',
        type: 'textarea',
        placeholder: 'Provide any relevant comments',
        size: 12,
        validators: ['required'],
      },
      {
        id: 'documents',
        label: 'Documents',
        formControlName: 'Documents',
        type: 'multiple-file-upload',
        placeholder: 'Upload related documents',
        size: 12,
        isInfoIcon: false,
        validators: [],
      },
    ],
  },
];
