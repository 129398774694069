import { TableColumnConfigurable } from '../../core/modals/table.modal';
import { DataCellType } from '../dto/table.dto';

export const statusHistoryColumConfig: TableColumnConfigurable[] = [
  {
    type: DataCellType.ColorLabel,
    field: 'status',
    header: 'Status',
    sortable: true,
    size: 120,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.User,
    field: 'updated_by',
    header: 'Updated By',
    sortable: false,
    size: 170,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Datetime,
    field: 'updated_at',
    header: 'Updated At',
    sortable: true,
    size: 170,
    isEnable: true,
    isRequired: true,
    isRawSpan: false,
  },
  {
    type: DataCellType.Para,
    field: 'comment',
    header: 'Comment',
    sortable: true,
    size: 220,
    isEnable: true,
    isRequired: true,
    isRawSpan: false,
  },
  {
    type: DataCellType.Documents,
    field: 'documents',
    header: 'Documents',
    sortable: true,
    size: 140,
    isEnable: true,
    isRequired: true,
    isRawSpan: false,
  },
];
