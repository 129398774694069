import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailsBoxComponent } from '../details-box/details-box.component';
import { ToggleButtonComponent } from '../../../buttons/toggle-button/toggle-button.component';
import { LabelDisplayComponent } from '../label-display/label-display.component';
import { SideFormComponent } from '../../../forms/side-form/side-form.component';
import { BasicTableComponent } from '../../../tables/basic-table/basic-table.component';
import {
  StatusDetailsLatestDTO,
  StatusHistoryDTO,
} from '../../../../dto/status-update.dto';
import { MainLoaderComponent } from '../../../loaders/main-loader/main-loader.component';
import { FormGroup } from '@angular/forms';
import { FormSection } from '../../../../models/form.config';
import { TableColumnConfigurable } from '../../../../../core/modals/table.modal';

@Component({
  selector: 'app-status-update',
  standalone: true,
  imports: [
    DetailsBoxComponent,
    ToggleButtonComponent,
    LabelDisplayComponent,
    SideFormComponent,
    BasicTableComponent,
    MainLoaderComponent,
  ],
  templateUrl: './status-update.component.html',
  styleUrl: './status-update.component.scss',
})
export class StatusUpdateComponent {
  @Input() latestDetails: StatusDetailsLatestDTO | null;
  @Input() isInitialLoading : boolean = false;
  @Input() isShowStatusForm: boolean = false;
  @Input() isShowHistory: boolean = false;
  @Input() formGroup: FormGroup;
  @Input() formSections: FormSection[];
  @Input() isFormDataLoading: boolean = false;

  @Output() toggleStatusFormRequest = new EventEmitter();
  @Output() closeStatusFormRequest = new EventEmitter();
  @Output() toggleStatusHistoryRequest = new EventEmitter();
  @Output() formSubmitRequest = new EventEmitter();

  @Input() isHistoryLoading: boolean = false;
  @Input() history: StatusHistoryDTO[] = [];
  @Input() historyColumns: TableColumnConfigurable[] = [];

  requestToggleStatusForm() {
    this.toggleStatusFormRequest.emit();
  }

  requestCloseStatusForm() {
    this.closeStatusFormRequest.emit();
  }

  requestToggleStatusHistory() {
    this.toggleStatusHistoryRequest.emit();
  }

  requestSubmitForm() {
    this.formSubmitRequest.emit();
  }
}
